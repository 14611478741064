import fly from "./flyio/flyConfig";
// 图片、视频展示域名
const commonImgOssDomain = "https://alioss.techops.cn/";
// 图片、视频下载域名
const downloadFileOssDomain = "https://cosmos-img.oss-cn-beijing.aliyuncs.com/";
// 二维码下载域名
const downloadQrCodeOssDomain =
	"https://cosmos-qrcode.oss-cn-beijing.aliyuncs.com/";
// 获取园区二维码
const getRegionQrCodeUrl = "/lease/estateManage/getRegionQrCode";
//企业后台
export * from "./enterpriseBack/index.js"

export {
	fly,
	commonImgOssDomain,
	downloadFileOssDomain,
	downloadQrCodeOssDomain,
	getRegionQrCodeUrl,
};
