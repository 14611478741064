<template>
    <div>
        <nav
                role="navigation" class="navbar-default navbar-static-side"
                id="menu-nav" ref="nav"
        >
            <div class="sidebar-collapse">
                <ul class="nav metismenu" id="side-menu"
                    :style="{'--nav-second-position-top': navSecondPositionTop + 'px'}">
                    <li class="nav-header nav-header-top" v-on:click="_gotoIndex()">
                        <div class="profile-element">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menua-lianhe4" class="icon-font"></use>
                            </svg>
                        </div>
                        <div class="logo-element">
                            <svg class="icon" aria-hidden="true" style="height: 74px;font-size: 48px;">
                                <use xlink:href="#icon-menua-logo2" class="icon-font"></use>
                            </svg>
                        </div>
                    </li>

                    <template v-for="menu in menus"
                              v-bind:class="{ active: menu.id === checkedMenu }"
                              >
                        <li :key="menu.icon" @click="navigationListener(menu.path)" class="hover-block hover-li"
                        >
                            <a>
                                <svg class="icon hover-item hover-block" aria-hidden="true">
                                    <use :xlink:href="'#' +  (setIcon(menu.path)?menu.icon:menu.icon1)"></use>
                                </svg>
                                <svg class="icon  hover-item" aria-hidden="true">
                                    <use :xlink:href="'#' +  (setIcon(menu.path)?menu.icon1:menu.icon1)"></use>
                                </svg>
                                <span class="nav-label"
                                      :style="{
                                              position: 'relative',
                                              color: setColor(menu.path),
                                            }">{{ menu.name }}

                                </span>

                            </a>
                        </li>

                    </template>

                </ul>
            </div>
        </nav>
    </div>
</template>
<script>
    import {getMenuUrl} from "@/requestUrl";
    import {STORAGE_KEY, THEMENU_ID} from "@/constant";

    export default {
        props: {},
        components: {},
        data() {
            return {
                navSecondPositionTop: 100,
                // 菜单和图标的映射
                menuNameIconMapping: {},
                badgeCircle: {
                    background: "red",
                    "border-radius": "50%",
                    width: "8px",
                    height: "8px",
                    display: "inline-block",
                    position: "absolute",
                    top: 0,
                    right: "-4px",
                },
                menus: [
                    {
                        name: '首页',
                        path: '/flow/index',
                        icon: 'icon-menushouye',
                        icon1: 'icon-menushouye-copy'
                    },
                    {
                        name: '公司员工',
                        path: '/flow/companyStaff',
                        icon: 'icon-menugongsiyuangong',
                        icon1: 'icon-menugongsiyuangong-copy'
                    },
                    {
                        name: '员工通行',
                        path: '/flow/employeeAccess',
                        icon: 'icon-menuyuangongtonghang',
                        icon1: 'icon-menuyuangongtonghang-copy'
                    },
                    {
                        name: '登记记录',
                        path: '/flow/loginRecord',
                        icon: 'icon-menudenglujilu',
                        icon1: 'icon-menudenglujilu-copy'
                    }
                ],
                checkedMenu: null,
                logo: "",
                socket: {},
            };
        },
        created() {
            this.checkedMenu = parseInt(sessionStorage.getItem(THEMENU_ID));
            if (this.$route.name == 'shortNoteBill') {
                return
            }
        },
        mounted() {

        },
        updated() {

        },
        methods: {


            _gotoIndex: function () {
                // sessionStorage.removeItem(STORAGE_KEY.ROUTE_HISTORY);
                // this.$vc.jumpToPage("/flow/index");
            },
            /**
             * url跳转监听
             * @param {Object} path 菜单路径
             * */
            navigationListener(path, id) {
                if (path == '/pushNull') {
                    this.$vc.toast("权限未开放，请联系管理员");
                    return
                }
                sessionStorage.removeItem(STORAGE_KEY.OPERATIONAL_QUERY_PARAMS);
                sessionStorage.removeItem(STORAGE_KEY.ROUTE_HISTORY);
                sessionStorage.setItem(THEMENU_ID, id);
                this.$router.push({path});
            },
            setColor(path) {
                return this.$route.path.indexOf(path) ? '#f0f0f0' : 'rgb(26, 179, 148)';
            },
            setIcon(path) {
                return this.$route.path.indexOf(path)
            }
        },
    };
</script>
<style lang="stylus" scoped>
    @import '../style/menuIcon.styl';
    #menu-nav {
        height: 100vh;
        overflow-y: auto;
        width: 180px;
        //display: inline-block;
        vertical-align: top;

        .nav-header {
            font-size: 20px;
            txt-align: center;
            color: #fff;
            padding: 11px 10px 4px;

            .profile-element {
                //margin-top: -11px;

                .icon {
                    width: 100%;
                    height: 70px;
                }
            }
        }

        .nav > li {
            overflow: visible;

            & > a {
                color: #676a6c;

                &:hover {
                    color: #fff;
                }

                svg {
                    margin-right: 6px;
                    font-size: 16px;
                }
            }
        }

        .nav-second-level, .nav-label {
            a {
                position: relative;

                &:hover {
                    span {
                        color: rgb(26, 179, 148) !important;
                    }
                }
            }

            .dot {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #ff0000;
                transform: translateX(-40%) translateY(-20%);
            }
        }
    }

    .sidebar-collapse {
        box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    }

    .fa {
        color: #f0f0f0
    }

    /*#menu-nav {
        -ms-overflow-style: none;
        scrollbar-width: none

        &::-webkit-scrollbar {
            display: none;
        }
    }*/

    .mini-navbar {
        #menu-nav {
            width: 70px;
            //overflow: visible;
            /*padding-right: 170px;
            margin-right: -170px;*/
            overflow-x: hidden;
            overflow-y: auto;

            .nav-second-level {
                a {
                    color: #fff !important;

                    span {
                        display: inline-block;
                        color: #fff !important;
                    }
                }
            }

            #side-menu > li {
                width: 70px;

                & > a {
                    width: 70px;
                }
            }

            .nav-second-level {
                position: fixed;
                left: 60px;
                top: var(--nav-second-position-top, 100px);
                z-index: 2010;
            }

            .nav-second-level li:last-child {
                padding-bottom: 0;
            }
        }


    }

    img {
        image-rendering: pixelated;
    }

    @media screen and (max-height: 965px) {
        .nav-header-top {
            padding: 0 0 4px 0 !important;
        }

        .profile-element {
            margin: 0 auto;
            width: 92%;
        }
    }

    a:hover .nav-label {
        color: rgb(26, 179, 148) !important
    }
    a .hover-item{
        display:none;
    }
    a .hover-block{
        display:inline-block;
    }
    a:hover .hover-item{
        display:inline-block;
    }
    a:hover .hover-block{
        display:none;
    }
    a:hover .fa {
        color: rgb(26, 179, 148) !important
    }
</style>

