var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        ref: "nav",
        staticClass: "navbar-default navbar-static-side",
        attrs: { role: "navigation", id: "menu-nav" },
      },
      [
        _c("div", { staticClass: "sidebar-collapse" }, [
          _c(
            "ul",
            {
              staticClass: "nav metismenu",
              style: {
                "--nav-second-position-top": _vm.navSecondPositionTop + "px",
              },
              attrs: { id: "side-menu" },
            },
            [
              _c(
                "li",
                {
                  staticClass: "nav-header nav-header-top",
                  on: {
                    click: function ($event) {
                      return _vm._gotoIndex()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "profile-element" }, [
                    _c(
                      "svg",
                      { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                      [
                        _c("use", {
                          staticClass: "icon-font",
                          attrs: { "xlink:href": "#icon-menua-lianhe4" },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "logo-element" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "icon",
                        staticStyle: { height: "74px", "font-size": "48px" },
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c("use", {
                          staticClass: "icon-font",
                          attrs: { "xlink:href": "#icon-menua-logo2" },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._l(_vm.menus, function (menu) {
                return [
                  _c(
                    "li",
                    {
                      key: menu.icon,
                      staticClass: "hover-block hover-li",
                      on: {
                        click: function ($event) {
                          return _vm.navigationListener(menu.path)
                        },
                      },
                    },
                    [
                      _c("a", [
                        _c(
                          "svg",
                          {
                            staticClass: "icon hover-item hover-block",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "#" +
                                  (_vm.setIcon(menu.path)
                                    ? menu.icon
                                    : menu.icon1),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "icon  hover-item",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "#" +
                                  (_vm.setIcon(menu.path)
                                    ? menu.icon1
                                    : menu.icon1),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "nav-label",
                            style: {
                              position: "relative",
                              color: _vm.setColor(menu.path),
                            },
                          },
                          [_vm._v(_vm._s(menu.name) + " ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }