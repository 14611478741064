//企业后台
//  后台登录页查询公告接口
const queryPCLoginUrl = "/social/notice/queryPCLogin";
//获取短信验证码
const sendSmsUrl = '/account/auth/sendSms';
//登陆
const smsSignInUrl = '/account/auth/smsSignIn';
// 获取菜单列表
const getMenuUrl = "/account/staff/getMenu";
//PC公司员工列表
const getCompanyByUserUrl = '/account/enterpriseLogin/getCompanyByUser';
//查询公司员工通行记录
const queryPassingLogByLesseeIdUrl = '/turnstile/queryPassingLogByLesseeId';
//查询员工登陆记录
const queryCompanyLoginLogUrl = '/account/enterpriseLogin/queryCompanyLoginLog';
//近10次登陆记录
const queryCompanyLoginLogsUrl = '/account/enterpriseLogin/queryCompanyLoginLog';
export {
    queryPCLoginUrl,
    sendSmsUrl,
    smsSignInUrl,
    getMenuUrl,
    getCompanyByUserUrl,
    queryPassingLogByLesseeIdUrl,
    queryCompanyLoginLogUrl,
    queryCompanyLoginLogsUrl
}