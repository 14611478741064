import {loadComponent, loadView} from './loadFn';


const basePath = "/flow";


const routes = [{
    path: "",
    name: "defaultPage",
    component: loadView("Login"),
},
    {
        path: basePath + "/login",
        name: "login",
        component: loadView("Login"),
    },
    {
        path: basePath,
        component: loadView("MainPage"),
        children: [{
            path: 'index',
            name: "index",
            component: loadView("homePage/index"),
            meta: {
                title: '首页'
            }
        },
        {
            path: 'companyStaff',
            name: "companyStaff",
            component: loadView("homePage/companyStaff"),
            meta: {
                title: '公司员工'
            }
        },
        {
            path: 'employeeAccess',
            name: "employeeAccess",
            component: loadView("homePage/employeeAccess"),
            meta: {
                title: '员工通行'
            }
        },
        {
            path: 'loginRecord',
            name: "loginRecord",
            component: loadView("homePage/loginRecord"),
            meta: {
                title: '登记记录'
            }
        }]
    },
    {
        path: "*",
        name: "errorPage",
        component: loadView("ErrorPage"),
    },
];

export default routes;
