<template>
    <div id="app" v-cloak>
        <Menu v-if="$route.path.includes('/flow')"></Menu>
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
import Menu from "@/views/Menu";

export default {
    name: "app",
    components: {
        Menu
    },
    destroyed() {
        this.$vc.clearCacheData();
    },
    mounted() {

    },
    updated() {
        setTimeout(() => {
            document.getElementsByTagName('select').forEach(select => {
                if (select.value !== '') {
                    select.classList.add('selected');
                } else {
                    select.classList.remove('selected');
                }
                select.addEventListener('change', (event) => {
                    const {selectedIndex} = event.target;
                    if (event.target[selectedIndex].value !== '' && event.target[selectedIndex].value !== undefined) {
                        event.target.classList.add('selected');
                    } else {
                        event.target.classList.remove('selected');
                    }
                })
            })
        }, 1000)

        // 防止刚好最后一次操作在select元素上时，回车查询再次展开select元素问题
        document.getElementsByTagName('select').forEach(select => {
            select.addEventListener('change', (e) => {
                e.target.blur();
            })
        })
    }
};
</script>

<style lang="stylus">
[v-cloak]
    display none
#app
    min-width 800px

</style>
