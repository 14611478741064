var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _vm.visible
      ? _c("div", { staticClass: "layer" }, [
          _c(
            "div",
            {
              staticClass: "cs-dialog",
              style: {
                "--dialog-width":
                  typeof _vm.width === "number" ? _vm.width + "px" : _vm.width,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onClick.apply(null, arguments)
                },
              },
            },
            [
              _vm.headerIcon
                ? _c(
                    "div",
                    {
                      class: [
                        "cs-dialog-header",
                        _vm.headerClass,
                        "headerIcon",
                      ],
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "icon",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#" + _vm.headerIcon },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm.title && _vm.title !== ""
                ? _c("div", { class: ["cs-dialog-header", _vm.headerClass] }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ])
                : _vm._e(),
              _vm._t("dialog-content", function () {
                return [
                  _vm.message != "" || _vm.messageHtml != ""
                    ? [
                        _c("div", { staticClass: "cs-dialog-content" }, [
                          _vm.messageHtml && _vm.messageHtml !== ""
                            ? _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.messageHtml),
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "text-align": "left",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.message) + " ")]
                              ),
                        ]),
                      ]
                    : _vm._e(),
                ]
              }),
              _vm.showCancelBtn || _vm.showConfirmBtn
                ? _c(
                    "div",
                    { staticClass: "cs-dialog-footer" },
                    [
                      _vm._t("dialog-footer", function () {
                        return [
                          _vm.showConfirmBtn
                            ? _c(
                                "button",
                                {
                                  class: [
                                    "btn",
                                    "btn-primary",
                                    {
                                      disabled:
                                        _vm.dialogShowConfirmBtnDisabled ||
                                        _vm.submittingStatus,
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.stopPropagation()
                                      return _vm.onConfirm.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.confirmBtnText) + " ")]
                              )
                            : _vm._e(),
                          _vm.showCancelBtn
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-cancel",
                                  style: _vm.showConfirmBtn
                                    ? ""
                                    : "background: #1ab394; color: #fff;",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onCancel.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.cancelBtnText) + " ")]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }